export default [
    {
        name: 'ANDHERA UJALA',
        embedId: '5B69yKV63cs',
    },
    {
        name: 'KAISE CHHODUN',
        embedId: 'qk5kxlE3NwE',
    },
    {
        name: 'SEEKHNE NAHI DETA',
        embedId: 'emSGEAgkjPg',
    },
    {
        name: 'Talab',
        embedId: 'W942K_4IooU',
    },
    {
        name: 'DIL KI DUTY',
        embedId: 'YCkIwDLhPVE',
    },
    {
        name: 'BIMARI KA EHAAS',
        embedId: 'jWbInWLwq-A',
    },
    {
        name: 'BAHUT PYAR AAYA',
        embedId: 'keHqIre7JtI',
    },
    {
        name: 'KYA HAM MAREEZ HAIN',
        embedId: 'n2_smFkiBrg',
    },
    {
        name: 'EK SHADI AISI BHI',
        embedId: 'ZnUQunipyfE',
    },
    {
        name: 'CHHOTE BUDGET ME BADA MAKAAN',
        embedId: '4EFmu2vBFp8',
    },
    {
        name: 'PARDA NA UTHAO',
        embedId: '66nXdSYkFZk',
    },
    {
        name: 'SUSTI DOOR',
        embedId: 'qV0OLoZ6fcQ',
    },
    {
        name: 'GHAR JANE SE PAHLE',
        embedId: 'QjGX94qSb-A',
    },
    {
        name: 'DIN BHAR USI SE BATEN',
        embedId: 't3jq7A2gmAg',
    },
    {
        name: 'DIL KAHAN LAGTA HAI',
        embedId: 'MqWTkiTpQco',
    },
    {
        name: 'HOLD PAR KOUN',
        embedId: 'Hr2Po84F770',
    },
    {
        name: 'SURAJ DOOBJANA KE BAAD',
        embedId: 'AFEY2ne7i9E',
    },
    {
        name: 'KHANA KHAYA KYA',
        embedId: 'E-Qx0bvTIu8',
    },
    {
        name: '2 BAJE TAK',
        embedId: 'MfvHinxBbso',
    },
    {
        name: 'TARTEEB',
        embedId: 'D68N9O92eZ4',
    },
    {
        name: 'RECHECK',
        embedId: '02Glce30Hsk',
    },
    {
        name: 'PYAR KA SATYANAS',
        embedId: 'zvf9BHBIWG0',
    },
    {
        name: 'MILA YA NAHI',
        embedId: 'gnHWh-RqeZ8',
    },
    {
        name: 'TARBIYAT KA DHANG',
        embedId: 'uMjUP8Icvgc',
    },
    {
        name: 'WIRASAT',
        embedId: 'WpcaYP-ZmAU',
    },
    {
        name: 'SEHAT MAND MAREEZ',
        embedId: 'cHQLVYu0KJE',
    },
    {
        name: 'SABKI KHARABI',
        embedId: 'x9qkRobpWR8',
    },
    {
        name: 'PAHLE DE PHIR JA',
        embedId: 'J1rwJ9VbERM',
    },
    {
        name: 'ISS DUA KI BARKAT',
        embedId: 'zRZaWzbAud4',
    },
    {
        name: 'BAATEN AASHIQON KI ?',
        embedId: 'klB7jKTyf0U',
    },
    {
        name: 'MAUT TAK SEHAT',
        embedId: '6Q0uPVrlKWE',
    },
    {
        name: 'JALD BAAZI',
        embedId: '5OK1hZpB-f8',
    },
    {
        name: 'HAM KAB SAMJHENGE',
        embedId: 'c_PXI1oE1WU',
    },
    {
        name: 'SAMAJHNA BHOOL GAYE',
        embedId: 'E18_ujTHWjE',
    },
    {
        name: 'DARNE KI BAAT',
        embedId: 'R1qQefFzCEU',
    },
    {
        name: 'ANJAAM',
        embedId: 'I-A5U3FOeyw',
    },
    {
        name: 'TO LOG KYA KAHENGE',
        embedId: 'xgBgeGJ66V8',
    },
    {
        name: 'TEACHER SE SHADI ',
        embedId: 'TPQ8Ilq3IDU',
    },
    {
        name: 'PUNCHER',
        embedId: 'DpkKb04mLtQ',
    },
    {
        name: 'GHUSSA AUR SABR',
        embedId: 'PA3JKiXQ9mI',
    },
    {
        name: 'GHUSSA KYA HAI',
        embedId: 'fKWgH8IRaxE',
    },
    {
        name: 'TERI SHADI HAI',
        embedId: 'qbr8L1ouQzY',
    },
    {
        name: 'ZULM SABR',
        embedId: '9R8n-Uqcn_0',
    },
    {
        name: 'GHEEBAT No.1',
        embedId: 'edaO7eXOly4',
    },
    {
        name: 'BETI SASURAL ME',
        embedId: '0I7YHoB_AWU',
    },
    {
        name: 'WAZEEFE',
        embedId: 'nzs9RQA4k3U',
    },
    {
        name: 'THODA SA ZAHAR',
        embedId: 'BtnYURJbBoQ',
    },
    {
        name: 'PARESHANI',
        embedId: 'OOuzCGWO908',
    },
    {
        name: 'DUA KYA HAI | NO.12 ',
        embedId: 'IDxfHKFaS2o',
    },
    {
        name: 'DUA KYA HAI | NO.11',
        embedId: '8PlQ_KFoGKo',
    },
    {
        name: 'DUA KYA HAI | NO.10',
        embedId: 'sISDRveqeqU',
    },
    {
        name: 'DUA KYA HAI | NO.9',
        embedId: 'zBiFax9dAf4',
    },
    {
        name: 'DUA KYA HAI | NO.8',
        embedId: 'fnhDUcFg1uU',
    },
    {
        name: 'DUA KYA HAI | NO.7',
        embedId: 'KyPZ3SQrzJk',
    },
    {
        name: 'DUA KYA HAI | NO.6',
        embedId: 'IOjMZDHVNEQ',
    },
    {
        name: 'DUA KYA HAI | NO.5',
        embedId: 'e8EMQGrUGuM',
    },
    {
        name: 'DUA KYA HAI | NO.4',
        embedId: 'hHvHdwI1bIQ',
    },
    {
        name: 'DUA KYA HAI | NO.3',
        embedId: 'bEqxZ96G9Wo',
    },
    {
        name: 'DUA KYA HAI | NO.2',
        embedId: 'e4ClH3yLtBk',
    },
    {
        name: 'DUA KYA HAI | NO.1',
        embedId: 'QDt6AsSEu40',
    },
    {
        name: 'EK NAWJAWAN ACHHA BETA ACHHA SHOHAR',
        embedId: 'uc-A6PahQEM',
    },
    {
        name: 'DIN SHURU HO GAYA',
        embedId: 'vKBZ2Dt3Kl8',
    },
    {
        name: 'SUKH KI TALASH',
        embedId: 'PWctnLzBotk',
    },
    {
        name: '3 PASANDEEDA CHEEZEN',
        embedId: 'rk3UpGjp4zY',
    },
    {
        name: '3 MAHBOOB CHIZEN',
        embedId: 'IBOnP_tmVrM',
    },
    {
        name: 'NAZLA BUKHAR YA',
        embedId: '7CIduoscc48',
    },
    {
        name: 'GOOD LIE',
        embedId: 't4JSaaKiT5k',
    },
    {
        name: 'JHOOT | NO.1',
        embedId: 'Ml-T7JDnnPQ',
    },
    {
        name: 'JHOOT | NO.2',
        embedId: 'gs2OHLdOXF0',
    },
    {
        name: 'JHOOT | NO.3',
        embedId: 'gxyce13rYfQ',
    },
    {
        name: 'JHOOT | NO.4',
        embedId: 'cpsitUZNHT0',
    },
    {
        name: 'POOCHH POOCHH KAR',
        embedId: '3WOiXYi4UQ8',
    },
    {
        name: 'KHUBI WALE KI KHARAABI',
        embedId: 'kqO3692hTfc',
    },
    {
        name: 'ZABAAN',
        embedId: '55RNHWOGtAM',
    },
    {
        name: 'NAYI NAYI BAATEN',
        embedId: 'TkoL2YX4aNA',
    },
    {
        name: 'BAATEN',
        embedId: 'XFwl19BJ_tQ',
    },
    {
        name: 'PASAND APNI APNI',
        embedId: 'g5Ltq-q0DXQ',
    },
    {
        name: 'SUB KE SAATH HAI',
        embedId: '_ZFJ8s2IzOc',
    },
    {
        name: '2 SAAL KA BACHCHA',
        embedId: 'VmZ9txwk2Sw',
    },
    {
        name: 'HAZRAT BANUNGA',
        embedId: 'nZOib025D-U',
    },
    {
        name: 'PARESHANIYON KA HAL 2',
        embedId: '8ueGIKxMGjM',
    },
    {
        name: 'PARISHANIYON KA HAL 1',
        embedId: '7gwVg_X-ljo',
    },
    {
        name: 'DUSHMAN SE GHAFLAT',
        embedId: 'b8kLrNCHFAE',
    },
    {
        name: 'ISTIGHFAR | NO. 4',
        embedId: '-MZlWv3QKTI',
    },
    {
        name: 'ISTIGHFAR | NO. 3',
        embedId: 'xSIYFIz_290',
    },
    {
        name: 'ISTIGHFAR | NO. 2',
        embedId: 'Ig4yUDHd8NY',
    },
    {
        name: 'ISTIGHFAR | NO. 1',
        embedId: 'VODwu6bzp58',
    },
    {
        name: 'EIMAN AUR SEHAT',
        embedId: 'Ial0n_3dGiY',
    },
    {
        name: 'TAZIYAT',
        embedId: 'hil3Z4UumN4',
    },
    {
        name: 'ZINDAGI BADAL JAYE',
        embedId: 'Hg6fQzXoFLg',
    },
    {
        name: 'HAYE MERE GUNAH',
        embedId: 'WDWXl4sCNDw',
    },
    {
        name: 'GUNAAH SE KAISE BACHEN',
        embedId: 'ReIyfT1OQbQ',
    },
    {
        name: 'GUNAH SE PAHLE',
        embedId: '2NLbAKzjdkw',
    },
    {
        name: 'NAMAZI KA PACHHTAWA',
        embedId: 'xFH6-m994qc',
    },
    {
        name: 'MERA RAB HAI',
        embedId: 'cyeHEZSaKuQ',
    },
    {
        name: 'SALAMATI CHAHTE HO TO',
        embedId: 'JA3vladLhGA',
    },
    {
        name: 'TEEN CHEEZEN',
        embedId: 'H8krc7Fbikw',
    },
    {
        name: 'CONNECTION',
        embedId: 'qZhUdu-o36o',
    },
    {
        name: 'RAABTA',
        embedId: 'QARPPQxXwBY',
    },
    {
        name: 'SEHAT MAND RAHNE KE LIYE',
        embedId: 'JChNb9zB8RA',
    },
    {
        name: 'ZULM APNI ZAAT PAR',
        embedId: '6Tgd_m7SqwQ',
    },
    {
        name: 'BIWI KI WAJAH SE',
        embedId: 'tESokET-QS0',
    },
    {
        name: '100 BIWIYAN',
        embedId: 'FVFzpt3UOS0',
    },
    {
        name: 'BIWI',
        embedId: 'fSviY2LNXHk',
    },
    {
        name: 'KABHI BIWI KABHI HAM',
        embedId: 'oGhokgsj7Qs',
    },
    {
        name: 'APNI BIWI KAB ACHHI LAGTI HAI',
        embedId: 'Ij2LyhcBGdM',
    },
    {
        name: 'MIYAN BIWI DONON ACHCHHE',
        embedId: '3eL7P8mm-ZA',
    },
    {
        name: 'QURBANI',
        embedId: 'mBSTT7X9a3M',
    },
    {
        name: 'HALAAT',
        embedId: 'ePgwh5MqJ28',
    },
    {
        name: 'SAWALAT',
        embedId: 'zbM40CHlPjs',
    },
    {
        name: 'REASON',
        embedId: 'YDFJ15OGHY0',
    },
    {
        name: 'IRADA',
        embedId: 'vGzqxZS7k4Q',
    },
    {
        name: 'KHUBIYON KI TALAASH',
        embedId: 'w-GmnUmEcW0',
    },
    {
        name: 'KABHI KABHI',
        embedId: 'iwM9RNrW45A',
    },
    {
        name: 'ASBAB AUR DUA',
        embedId: 'kIo-sX0OjrI',
    },
    {
        name: 'PRACTICE',
        embedId: 'CKqUWYSBVJ4',
    },
    {
        name: 'MUJHE BACHALIJIYE',
        embedId: 'dhfiNPC4h3I',
    },
    {
        name: 'EK SAFAR',
        embedId: 'VE1MutMn2cg',
    },
    {
        name: 'GHAR KI BAAT HAI',
        embedId: '6XB-SqktHnc',
    },
    {
        name: 'GHAR KE ANDAR',
        embedId: '9CISi7hD7B0',
    },
    {
        name: 'GHAR BADA HO JAYE',
        embedId: 'ok4QhgQRUmg',
    },
    {
        name: 'PAKEEZA MAAL',
        embedId: 'TXzzFRar5_k',
    },
    {
        name: 'JANAB JI',
        embedId: 'Nu-vOf4axKg',
    },
    {
        name: 'BHOOL JAO',
        embedId: 'VTk9ZzbfQFs',
    },
    {
        name: 'TO TUM KYA KARTE HO',
        embedId: 'YTavv17M_4Q',
    },
    {
        name: 'WAQT',
        embedId: 'JBreQpKfYmU',
    },
    {
        name: 'UMMAT SA MOHABHAT',
        embedId: 'mwVW9WmVddY',
    },
    {
        name: 'PYAR PYAR PYAR',
        embedId: 'ycbMvC8D7oQ',
    },
    {
        name: 'KAISE SAMJHAUN',
        embedId: 'g8YAzA1XInU',
    },
    {
        name: 'SAMJHANE KA DHANG',
        embedId: '4F36-EsI2MU',
    },
    {
        name: 'KARGUZAARI',
        embedId: 'e5BvmUZo19s',
    },
    {
        name: 'AAJ PHIR SUBAH HO GAYI',
        embedId: 'HXfSD65fH8M',
    },
    {
        name: 'MUJAHADA',
        embedId: 'v8h4ZJQr09E',
    },
    {
        name: 'HAYE HAYE',
        embedId: 'BNxbL8satrU',
    },
    {
        name: 'GANDA DIL',
        embedId: '9TNz0mwdkf0',
    },
    {
        name: 'GUMRAAH',
        embedId: 'MJhx6ZKWrv0',
    },
    {
        name: 'APNA HISAB KITAB',
        embedId: 'MZkpExBcQwA',
    },
    {
        name: 'SERIOUS',
        embedId: 'fa8pBsHOi44',
    },
    {
        name: 'KHAT',
        embedId: '0VBLyRVxMh4',
    },
    {
        name: 'SHATIRANA CHAAL',
        embedId: 'dqBC_e35s20',
    },
    {
        name: 'BE KHABAR',
        embedId: 'DLMDm2zawK4',
    },
    {
        name: 'KHIDMAT AUR SABR',
        embedId: 'fx_tL6-e9XU',
    },
    {
        name: 'KHANA AUR SABR',
        embedId: 'qFBCtBjajtk',
    },
    {
        name: 'YAAD KE SAATH',
        embedId: 'vmDU4YzMq1E',
    },
    {
        name: 'KYA SHAITAN NE HUMKO CHHOD DIYA',
        embedId: 'Ays78Ie_9SE',
    },
    {
        name: 'KAMIYAN NA DEKHEN',
        embedId: 'S3lqehyKgV0',
    },
    {
        name: 'ACHCHHAIYAN DEKHEN',
        embedId: '5VxGy1TwhUQ',
    },
    {
        name: 'HUM KAISE LAGTE HAIN',
        embedId: '45x_4-_6lA4',
    },
    {
        name: 'KHIDMAT AUR SABR',
        embedId: 'fx_tL6-e9XU',
    },
    {
        name: 'TOD JOD',
        embedId: 'fYqG1m6xu-U',
    },
    {
        name: 'NAFS SE BAATEN',
        embedId: 'LDaNsMKS6RQ',
    },
    {
        name: 'MAQBOOL ROZE KI PAHCHAN',
        embedId: '9YOv63LHs1c',
    },
    {
        name: 'SHABE QADAR KA MAHROOM',
        embedId: 'GyVmHl4XOjA',
    },
    {
        name: 'TAAL DEN !',
        embedId: 'wTm1JFPBU4U',
    },
    {
        name: 'DUA 23RD RAMAZAN',
        embedId: 'r7lxm48pmBE',
    },
    {
        name: 'TEA',
        embedId: 'NGL2alYFE4g',
    },
    {
        name: 'SAARI TAREEF ALLAH HI KE LIYE',
        embedId: '33RMsyQxtYc',
    },
    {
        name: 'PURI TAWAJJOH',
        embedId: 'k0lx1i1M72Q',
    },
    {
        name: 'USOOL',
        embedId: 'LHdNUeeOBR0',
    },
    {
        name: 'TARTIB WALA MIZAAJ',
        embedId: 'lOYOJhE06u0',
    },
    {
        name: 'KHABAR',
        embedId: 'oD8uLWNNdDw',
    },
    {
        name: 'KATI PATANG',
        embedId: 'og0144s53xQ',
    },
    {
        name: 'KYA PAYA',
        embedId: 'ksjbCxIRDBs',
    },
    {
        name: 'MENU',
        embedId: 'Pn1iP5stbSQ',
    },
    {
        name: 'RAMAZAN KI RAAT',
        embedId: 'Lrir1NdGgoI',
    },
    {
        name: 'KYA KAMAYA',
        embedId: 'FSK96X-YwEA',
    },
    {
        name: 'KAIFIYAT',
        embedId: 'H4moFKOLVDc',
    },
    {
        name: 'ALLAH DEKH RAHA HAI',
        embedId: 'L1jwo9SxU9c',
    },
    {
        name: 'MOMIN KA DIL',
        embedId: 'we5Z8Zr8-Tc',
    },
    {
        name: 'SUBAH KI NIYAT',
        embedId: 'VXwXIzrNhwE',
    },
    {
        name: 'SHAITAN AUR AABID',
        embedId: 'dSV9Yjc8WT8',
    },
    {
        name: 'RAHMATON KI BARISH',
        embedId: 'HrmAMFzIfXk',
    },
    {
        name: 'HISAB LAGATE RAHEN',
        embedId: 'h7Gjl9CK6y4',
    },
    {
        name: 'YADON KE ZARIYE',
        embedId: 'ER9NSig0DAI',
    },
    {
        name: 'KYA KIYA',
        embedId: 'ZOlH1UEdjIE',
    },
    {
        name: 'MAUSAM',
        embedId: 'vKti92kOodU',
    },
    {
        name: 'BEWAQUF BAAP',
        embedId: 'vwokm4JTzIw',
    },
    {
        name: 'SHAB E BARAAT',
        embedId: 'qdNoQRTx13s',

    },
    {
        name: 'RAMAZAN SE PAHLE',
        embedId: 'uwTBrq634wE',

    },
    {
        name: 'RAMAZAN AAGAYA',
        embedId: 'oaveny85lOg',

    },
    {
        name: 'RAMAZAN KI TAIYARI',
        embedId: 'M54CKuprQ4o',

    },
    {
        name: 'ISS RAMAZAN ME',
        embedId: 'oY6lBzPqTeM',

    },
    {
        name: 'BANI TO BANA',
        embedId: 'qWIzRnxyFmM',

    },
    {
        name: 'HAJI SAHAB NAMAZI SAHAB',
        embedId: 'jeKlUvTwq68',

    },
    {
        name: 'NAMAZ ME KAPDA',
        embedId: 'TCPcY53y4Y8',

    },
    {
        name: 'NAMAZ BA JAMAT',
        embedId: 'wpOmZiT_290',

    },
    {
        name: 'MILA KE NAHI MILA',
        embedId: 'uRwztYP44xo',

    },
    {
        name: 'TAQWA',
        embedId: 'v979mybMIAg',

    },
    {
        name: 'PHATA NOTE',
        embedId: 'LxlZnk1cR9M',

    },
    {
        name: 'ROZA AUR SEHAT',
        embedId: 'alNspT2UkyE',

    },
    {
        name: 'HAMEN KYA KARNA HAI',
        embedId: 'uU4FhIgiAv4',

    },
    {
        name: 'BAANT KAR KHUSHI',
        embedId: 'rUVo-sELYQE',

    },
    {
        name: 'KHAMOSHI',
        embedId: 'Mo_HV0AN-Sk',

    },
    {
        name: 'TARGET',
        embedId: 'pBDkBGkSgJo',

    },
    {
        name: 'YE KAAM PAHLE KAR LEN',
        embedId: 'VbOr-7FKgew',

    },
    {
        name: 'ALLAH KI MACHINE',
        embedId: 'uGC4Y9KvvG8',

    },
    {
        name: 'DHOKHE PAR DHOKHA',
        embedId: 'GQ3elW0PKlw',

    },
    {
        name: 'GAS',
        embedId: 'wgsBAIkK1XY',

    },
    {
        name: 'GUNAAH SE KAISE BACHEN ',
        embedId: 'ReIyfT1OQbQ',

    },
    {
        name: 'ZALIM KOUN',
        embedId: '9txXzDN-53M',

    },
    {
        name: 'KOUN QAREEB KOUN DOOR',
        embedId: 'RnpETCHRNC4',

    },
    {
        name: 'INDIAN VS WESTERN TOILET',
        embedId: '32v14MaI8EI',

    },
    {
        name: 'AKHLAQ KA IMTIHAN',
        embedId: 'N_D9YczjxmU',

    },
    {
        name: 'FAZAEL AUR YAQEEN',
        embedId: 'vEf96-7zbWY',

    },
    {
        name: 'STAR',
        embedId: 'Gf8S_9X48yc',

    },
    {
        name: 'MACHHAR',
        embedId: 'ATc0aXdrFwo',

    },
    {
        name: '1 ME DARD 9 ME NAHI',
        embedId: 'MFNG24lFKHE',

    },
    {
        name: 'UMAR KA TAQAZA',
        embedId: 'RHiFDMqWZnQ',

    },
    {
        name: 'GHUSSA MAT DILAO',
        embedId: 'EtIazIwQ4rg',

    },
    {
        name: 'MOMIN KI FIKR',
        embedId: 'Q61uIt6sQRE',

    },
    {
        name: 'SHAR ME KHAIR',
        embedId: '0CGTjPG3eqk',

    },
    {
        name: 'NIBHANE KA NOOR',
        embedId: 'XYXDqH4vSqo',

    },
    {
        name: 'TAALNE KI AADAT',
        embedId: 'K3G_agyho2c',

    },
    {
        name: 'ACHCHHI BAAT',
        embedId: 'WZ_8keUh8SQ',

    },
    {
        name: 'MERI LADAAI',
        embedId: 'rdUAk1WG28M',

    },
    {
        name: 'ACHCHHA NATEEJA',
        embedId: 'G8DlAd6O_w0',

    },
    {
        name: 'BACKGROUND KI AAWAZ',
        embedId: 'Rx3SLxsZNOM',

    },
    {
        name: 'DUSHMAN',
        embedId: 'Voo5T0wRhP8',

    },
    {
        name: 'ACHCHHE LAGOGE',
        embedId: 'vWgKWpdEaW8',

    },
    {
        name: 'KHAYALON PE QABZA',
        embedId: 'xm3sOixPnFo',

    },
    {
        name: 'CAMERE KA RUKH',
        embedId: 'wLgmGwFvhf4',

    },
    {
        name: 'POTEE KI RUKHSATY',
        embedId: 'X7tRoolgwfs',

    },
    {
        name: 'BETI AUR BAHU',
        embedId: 'la4Ev2OvYPo',

    },
    {
        name: 'KAHIN KUCHH KAHIN KUCHH',
        embedId: 'NDn_G54_7hs',

    },
    {
        name: 'ZAROORAT YA CHAAHAT',
        embedId: '8MfEte8s-nA',

    },
    {
        name: 'DOORI',
        embedId: 't6aYtkkPqQw',

    },
    {
        name: 'AAG',
        embedId: 'gV-TZarmva8',

    },
    {
        name: 'ADVERTISE',
        embedId: '0By_YgqKxpQ',

    },
    {
        name: '2 KAIFIYAT',
        embedId: 'Omh5HyERl8k',

    },
    {
        name: 'BADAM',
        embedId: 'bMGqgu2pTmA',

    },
    {
        name: 'CHARGER',
        embedId: 'xbX6Pw5sM0I',

    },
    {
        name: 'RESULT',
        embedId: 'KJxZ6A1s5AQ',

    },
    {
        name: 'KAAM KI TARTEEB',
        embedId: 'gCuRqldaUhg',

    },
    {
        name: '5 KG PYAAZ',
        embedId: 'NxY0lfAOesU',

    },
    {
        name: 'NEWS',
        embedId: 'l313clKb-8U',

    },
    {
        name: 'HAMDARDI',
        embedId: 'ewlHekipru0',

    },
    {
        name: 'MEHERBANI',
        embedId: '5Q2OWQ3FodU',

    },
    {
        name: 'ALLAH KI YAAD KE LIYA',
        embedId: 'BJbHCUvbj7A',

    },
    {
        name: 'NETWORK',
        embedId: 'tiNRAHT-WOc',

    },
    {
        name: 'SIRF AIK SOCH',
        embedId: 'z2qOO3hi0eI',

    },
    {
        name: 'AARAHI HAIN JARAHI HAIN',
        embedId: 'DdZFi1DrrT0',

    },
    {
        name: 'ACHCHHA SAMAJHTA THA',
        embedId: '2kqm9oPHUq0',

    },
    {
        name: 'SWITCH BOARD',
        embedId: '_Osb2OR80QQ',

    },
    {
        name: 'MASHWERA',
        embedId: 'fG5lMlrRl3U',

    },
    {
        name: '24 HOURS MAAFI',
        embedId: 'rRg3cYmrG9Q',

    },
    {
        name: 'MANOOS AAWAZ',
        embedId: 'd5RN_hJ_Z34',

    },
    {
        name: 'KAHAAN HAI TOO ... ?',
        embedId: 'xuPrkp7q-JY',

    },
    {
        name: 'BEHTREEN SURNAME',
        embedId: 'JebE0b7RBR8',

    },
    {
        name: '2 BAATEN',
        embedId: 'chPhxMGpHec',

    },
    {
        name: 'MAIN TUMHEN TUM HAMEN',
        embedId: 'Y6V1XB6USeU',

    },
    {
        name: 'ISKO BULAO USKO BULAO',
        embedId: 'A1gap0DZp2c',

    },
    {
        name: 'AARAHI HAIN JARAHI HAIN',
        embedId: 'DdZFi1DrrT0',

    },
    {
        name: 'KAHAN HAIN ? ',
        embedId: '02KDWzh9urA',

    },
    {
        name: 'MAIN KYA HOON ...?',
        embedId: 'RRUYXhfcyiY',

    },
    {
        name: 'GANNE KI KHETI',
        embedId: 'v9oO_69R6Fo',

    },
    {
        name: 'ACHCHHE ABBU',
        embedId: 'pBYOQTgn9-w',

    },
    {
        name: 'WAH WAH !!!',
        embedId: 'UylrVpP-UwM',

    },
    {
        name: 'MAAN KI KHUSHI',
        embedId: '3xcmyJR_14k',

    },
    {
        name: 'MAAL BADHEGA',
        embedId: 'z54ISz6NKK4',

    },
    {
        name: 'AAMAL MAAL',
        embedId: 'ZrVSjp7-ON4',

    },
    {
        name: 'PAKEEZA MAAL',
        embedId: 'DE2zbrF14Hg',

    },
    {
        name: 'MAIN NA BADAL JAOON',
        embedId: 'JuR3X1Hja-U',

    },
    {
        name: 'BADA KAUN ?',
        embedId: '2bA6hdGKiF0',

    },
    {
        name: 'AAP KE LIYA',
        embedId: 'XwviY4i4Jv0',

    },
    {
        name: 'KAAM BAN JAAYEGA',
        embedId: 'Q-wSXDC87Ys',

    },
    {
        name: 'HUSN ME KHOKAR',
        embedId: 'XV54ZOh-r7g',

    },
    {
        name: 'GAALI YA SHER ?',
        embedId: '_gcEwWkdJq4',

    },
    {
        name: 'BADA BEWAQOOF',
        embedId: 'fsKB9GFYmTg',

    },
    {
        name: 'NOOR HI NOOR',
        embedId: 'RChpXVbHHLM',

    },
    {
        name: 'CHOCOLATE',
        embedId: '6F6mf2vpggQ',

    },
    {
        name: 'CHASHMA',
        embedId: 'iaWEpzZ0Y-s',

    },
    {
        name: 'KAPDA AUR DILL',
        embedId: 'LgP0jYVZ3jE',

    },
    {
        name: 'FAORAN KARLO',
        embedId: 't4H8AFB7N_k',

    },
    {
        name: 'RETURN GIFT',
        embedId: 'SC7bfKoc2iQ',

    },
    {
        name: 'KAAM WAALI',
        embedId: '43mDslYhI3s',

    },
    {
        name: 'HAZRAT',
        embedId: '0twKp-Qv-y8',

    },
    {
        name: 'AQALMAND',
        embedId: 'H9dVUe4rGKg',

    },
    {
        name: 'KOSHISH',
        embedId: 'qtZCD7U95AI',

    },
    {
        name: 'JANNAT KA MAZA',
        embedId: 'XUTPhQSElg0',

    },
    {
        name: 'SOTE WAQT',
        embedId: 'jAvwhQhOxHk',

    },
    {
        name: 'ALLAH KI MEHARBANI',
        embedId: '6la6CUuPAD8',

    },
    {
        name: 'TIFFIN',
        embedId: 'S5lHDQVskrI',

    },
];
