import React, { Component } from "react";
import { Pagination } from "antd";
import { observer } from "mobx-react";
import "./youtube.scss";

const Youtube = observer(
    class extends Component {
        handleClick = page => {
            this.props.store.currentPage = Number(page);
        };

        render() {
            const { store, itemRender } = this.props;
            const {
                youtube,
                itemsPerPage,
                currentPage,
                fetchBayans,
            } = store;
        
            return (
                <main className="short-clips">
                    <section>
                        <Pagination
                        current={currentPage}
                        onChange={this.handleClick}
                        total={youtube.length}
                        pageSize={itemsPerPage}
                        itemRender={itemRender}
                        />
                    </section>
                    <section
                        className="grid"
                    >
                        {
                            fetchBayans(youtube).map((item) => (
                                <article key={item.name}>
                                    <h3>{item.name}</h3>
                                    <div className="aspect-ratio">
                                        <iframe
                                            title={item.title}
                                            src={`https://www.youtube.com/embed/${item.embedId}`}
                                            allowFullScreen
                                            allow="
                                                accelerometer;
                                                autoplay;
                                                clipboard-write;
                                                encrypted-media;
                                                gyroscope;
                                                picture-in-picture
                                            "
                                        />
                                    </div>
                                    <p>
                                        {item.date}
                                    </p>
                                </article>
                            ))
                        }
                    </section>
                </main>
            );
        }
    }
);

export default Youtube;
